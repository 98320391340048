'use client'
import { ToastAction } from '@/components/ui/toast'
import { useToast } from '@/hooks/use-toast'
import { ColorType } from '@/utils/APIRouteTypes'
import { Properties } from '@/utils/properties'
import { isEqual } from 'lodash'
import Link from 'next/link'
import React, { createContext, SetStateAction, useEffect, useReducer, useState } from 'react'
import { useCommonContext } from './CommonContext'
import { useSession } from 'next-auth/react'
import { useSessionStorageContext } from './SessionStorageContextProvider'
import { checkGeneratedImages, generateImages as generateImagesFromServer } from '@/server/server-actions'
import { usePathname, useSearchParams } from 'next/navigation'

export enum ImageGenActions {
    CLEAR = 'CLEAR',
    MARK_CLICKED = 'MARK_CLICKED',
    ADD_IMAGE = 'ADD_IMAGE',
    REMOVE_IMAGE = 'REMOVE_IMAGE',
    INITIALIZE = 'INITIALIZE'
}

type GeneratedImagesContextType = {
    src: string,
    isClicked: boolean,
    color: ColorType
}[]

type GeneratedImagesAction =
    | { type: ImageGenActions.CLEAR }
    | { type: ImageGenActions.MARK_CLICKED, payload: { src: string } }
    | { type: ImageGenActions.ADD_IMAGE, payload: { src: string, color: ColorType }[] }
    | { type: ImageGenActions.REMOVE_IMAGE, payload: { src: string } }
    | { type: ImageGenActions.INITIALIZE, payload: GeneratedImagesContextType }

const GeneratedImagesContext = createContext<[GeneratedImagesContextType, React.Dispatch<GeneratedImagesAction>, (prompt: string, slug?: string, color?: ColorType, onComplete?: () => void) => void] | null>(null)

const checkBadPrompt = (prompt: string) => {
    const lowerPrompt = prompt.toLowerCase()
    const bannedWords = [
        't-shirt',
        'tshirt',
        't shirt',
        'phone case',
        'phone cover',
        'tank top',
        'tote bag',
        'poster',
        'totebag'
    ]
    return bannedWords.find(word => lowerPrompt.includes(word)) || false;

}

const reducer = (state: GeneratedImagesContextType, action: GeneratedImagesAction): GeneratedImagesContextType => {
    switch (action.type) {
        case ImageGenActions.ADD_IMAGE:
            return [
                ...action.payload.filter((val) => !state.some(item => item.src === val.src))
                    .map(item => ({ src: item.src, isClicked: false, color: item.color })),
                ...state
            ]
        case ImageGenActions.REMOVE_IMAGE:
            return state.filter(item => item.src !== action.payload.src)
        case ImageGenActions.MARK_CLICKED:
            return state.map(item => item.src === action.payload.src ? { ...item, isClicked: true } : item)
        case ImageGenActions.CLEAR:
            return []
        case ImageGenActions.INITIALIZE:
            return action.payload
        default:
            return state
    }
}

export default function GeneratedImagesContextProvider({ children }: { children: React.ReactNode }) {

    const [value, setValue] = useReducer(reducer, null, () => {
        if (typeof window === 'undefined') return []
        try {
            const x = localStorage.getItem('promptImages')
            let json = JSON.parse(x || '[]')
            //check type of json
            if (!Array.isArray(json) || json.some((x: any) => typeof x !== 'object' || typeof x.src !== 'string' || typeof x.isClicked !== 'boolean' || typeof x.color !== 'string')) {
                json = []
            }
            return json
        }
        catch (e) {
            console.error(e)
            return []
        }
    })
    const { toast } = useToast()
    const [_, setCommonContext] = useCommonContext()
    const session = useSession()
    const [sessionStorage, setSessionStorage] = useSessionStorageContext()
    const path = usePathname()


    useEffect(() => {

        if (sessionStorage.isGeneratingImage && sessionStorage.predictionID) {
            const interval = setInterval(() => {
                if (sessionStorage.predictionID)
                    updateImages(sessionStorage.predictionID, interval, sessionStorage.colorMode)
            }, 3000)
            return () => clearInterval(interval)
        }
    }, [sessionStorage.isGeneratingImage, sessionStorage.predictionID])

    useEffect(() => {
        if (value !== null)
            localStorage.setItem('promptImages', JSON.stringify(value))
    }, [value])


    const onToastActionClick = () => {
        setCommonContext(prev => ({ ...prev, loginModalOpen: true, loginModalAction: 'imageGen' }))
    }

    const updateImages = async (rid: number, interval: NodeJS.Timeout, color?: ColorType) => {
        const req = await checkGeneratedImages(rid);

        const currentTime = Date.now()
        const createdTime = new Date(req.created).getTime()
        const timeDiffSecs = (currentTime - createdTime) / 1000

        if (timeDiffSecs > 90) {
            toast({
                title: "Something went wrong!",
                description: "Your design generation is taking longer than expected. Please try again.",
                variant: 'destructive',
            })
            setSessionStorage(prev => (
                { ...prev, isGeneratingImage: false, predictionID: undefined, prompt: undefined, colorMode: undefined }
            ))
            return
        }

        if (
            req.images &&
            !isEqual(req.images, value)
        ) {
            setValue({ type: ImageGenActions.ADD_IMAGE, payload: req.images.map((item) => ({ src: item.src, color: color || ColorType.LIGHT })) });
        }

        if (req.isDone) {
            clearInterval(interval)
            setSessionStorage(prev => (
                { ...prev, isGeneratingImage: false, predictionID: undefined, prompt: undefined, colorMode: undefined }
            ))
            if (window.location.pathname?.split('?')[0] !== sessionStorage.predictionPage?.split('?')[0]) {
                toast({
                    title: "Your Design is Ready!",
                    description: "Your design is ready. Try it out.",
                    action: <ToastAction altText="See Design" asChild><Link href={sessionStorage.predictionPage || Properties.routes.EXPLORE}> See Design!</Link></ToastAction>
                })
            }
        }
    };


    const generateImages = async (prompt: string, slug?: string, color?: ColorType, onComplete?: () => void) => {
        const promptInvalid = checkBadPrompt(prompt)
        if (promptInvalid) {
            toast({
                title: "Bad Prompt",
                description: `Word "${promptInvalid}" is not allowed in the prompt.`,
                variant: 'destructive',
                duration: 6000,
                action: <ToastAction altText="See Examples" asChild><Link href={Properties.routes.EXPLORE}> See Examples</Link></ToastAction>
            })
            return
        }

        if (session.status !== 'authenticated' && sessionStorage.numImagesGenerated > 0) {
            window.dataLayer?.push({
                event: 'generateImage',
                prompt: prompt,
                askingForLogin: true,
                userID: null
            })

            toast({
                title: "Login Required",
                description: "Please login to continue. It is completely free!",
                variant: 'destructive',
                duration: 10000,
                action: <ToastAction altText="Login" onClick={onToastActionClick} onTouchStart={onToastActionClick}>Login</ToastAction>
            })
            return
        }

        window.dataLayer?.push({
            event: 'generateImage',
            prompt: prompt,
            askingForLogin: false,
            userID: session.data?.user?.email
        })

        if (prompt) {
            setSessionStorage(prev => (
                {
                    ...prev,
                    isGeneratingImage: true,
                    numImagesGenerated: (sessionStorage.numImagesGenerated || 0) + 1,
                }
            ))
            const response = await generateImagesFromServer(prompt, value.map(item => item.src), slug, color);

            if (response.isKnownCharacter) {
                toast({
                    title: "Warning!",
                    description: "Our model doesn't work with known people, anime characters, TV Shows, etc. This is just a warning. Your design is still being generated.",
                    variant: 'destructive',
                    duration: 10000,
                })
            }

            if (response.requestId) {
                const searchParamsString = new URLSearchParams(window.location.search)
                setSessionStorage(prev => (
                    {
                        ...prev,
                        isGeneratingImage: true,
                        predictionID: response.requestId,
                        predictionPage: path + "?" + searchParamsString.toString(),
                    }
                ))
            }

            else {
                if (response?.login) {
                    toast({
                        title: "Login Required",
                        description: "Please login to continue. It is completely free!",
                        variant: 'destructive',
                        duration: 10000,
                        action: <ToastAction altText="Login" onClick={onToastActionClick} onTouchStart={onToastActionClick}>Login</ToastAction>
                    })

                }
                else
                    toast({
                        description: response.error || "Something went wrong. Please try again later.",
                        variant: 'destructive',
                        duration: 3000
                    })

                setSessionStorage(prev => (
                    { ...prev, isGeneratingImage: false }
                ))
            }
        }
    }

    return (
        <GeneratedImagesContext.Provider value={[value, setValue, generateImages]}>
            {children}
        </GeneratedImagesContext.Provider>
    )
}

export const useGeneratedImages = () => {
    const context = React.useContext(GeneratedImagesContext)
    if (context === null) {
        throw new Error('useCommonContext must be used within a CommonContextProvider')
    }
    return context
}
