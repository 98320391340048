type ListResponse<T> = {
    count: number;
    next: string | null;
    previous: string | null;
    results: T[];
}

export enum PredictionStatus {
    PENDING = 'Pending',
    SUCCESS = 'Success',
    FAILED = 'Failed'
}

export type PredictionObject = {
    id: number
    status: PredictionStatus
    output: PredictionImageType[]
    isKnownCharacter: boolean
    created: string
}

export type PredictionImageType = {
    image: string | null
}

export type UploadedImageType = {
    image: string
}

export type ProductList = ListResponse<ProductListItem>

export type PaymentObject = {
    created_ts: string
    price: number
    rzp_id: string
    cartItems: CartItemType[]
    status: 'Pending' | 'Success' | 'Failed' | 'COD'
}

export type ProductListItem = {
    slug: string;
    name: string;
    price: number;
    image: string | null
    category: ProductItemCategory;
    colors: ColorListItemFromAPI[];
    initialSize: string
    url: string;
    itemsAvailable: number;
    MRP: number;
    material: string
    initialColor: string
    blurDataURL: string | null
}
export type ProductDetail = {
    id: number
    slug: string;
    imgWidth: number | null
    imgHeight: number | null
    colors: ColorFromAPI[];
    sizes: SizeFromAPI[];
    boundingBoxFront: BoundingBoxFromAPI;
    boundingBoxBack: BoundingBoxFromAPI | null
    MRP: number;
    itemsAvailable: number;
    name: string;
    material: string;
    image: string;
    description: string;
    price: number;
    category: ProductItemCategory;
    additionalDetails: string[];
    sizeChart: string | null
    metaTitle: string | null
    metaDescription: string | null
    initialScale: number
    initialX: number | null
    initialY: number | null
}

export type BoundingBoxFromAPI = {
    id: number;
    x: number;
    y: number;
    width: number;
    height: number;
};

export enum ColorType {
    LIGHT = 'Light',
    DARK = 'Dark',
    BRIGHT = 'Bright'
}

export type ColorListItemFromAPI = {
    name: string;
    hexCode: string;
}

export type ColorFromAPI = {
    id: number;
    images: ImageFromAPI[];
    sizes: SizeFromAPI[];
    name: string;
    hexCode: string;
    colorType: ColorType;
    isAvailable: boolean;
};

export type SampleImageResponse = ListResponse<SampleImage>

export type SampleImage = {
    id: number;
    image: string;
    category: ProductItemCategory;
}

export type ImageFromAPI = {
    id: number;
    image: string;
    altText: string;
    side: ImageSide
};

export enum ImageSide {
    FRONT = "Front",
    BACK = "Back",
    LEFT = "Left",
    RIGHT = "Right",
}

export enum ProductItemCategory {
    MEN = "Men",
    WOMEN = "Women",
    BOYS = "Boys",
    GIRLS = "Girls",
    ACCESSORIES = 'Accessories'
}

export type SizeFromAPI = {
    id: number;
    name: string;
    abbreviation: string;
    cameraBox: BoundingBoxFromAPI | null
    isCircular: boolean
    priority: number
};

export type CatalogItem = {
    imageWithTShirt: string
    prompt: string
    designSrc: string
    color: string
    height: number
    width: number
    bgMode: ColorType
}

export type CatalogItemList = ListResponse<CatalogItem>

export type MyDesignObjectType = {
    output: PredictionImageType[]
    prompt: string
    bgMode: ColorType
}

export type WishListItem = {
    colorsExist: boolean
    imgHeight: number
    imgWidth: number
    productDetails: ProductListItem
    boundingBoxFront: BoundingBoxFromAPI
    boundingBoxBack: BoundingBoxFromAPI | null
    context: Object
    selectedColor: ColorFromAPI | null
    selectedSize: SizeFromAPI | null
    productID: number
}

export type MyDesignListType = ListResponse<MyDesignObjectType>
export type WishListType = ListResponse<WishListItem>
export type CartItemType = WishListItem & {
    quantity: number
    id: number
}

export type CartItemUpdateType = {
    quantity: number
}


export type CartListType = ListResponse<CartItemType>